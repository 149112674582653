import {
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  useCallback
} from 'react';
import camelcaseKeys from 'camelcase-keys';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import debounce from 'lodash/debounce';
import { DesignFeedbackProps, DesignFeedbackState, NavParams } from './types';
import { handleSharingClick } from '../../../clients/design_assets/handleSharingClick';
import { useOrganizationState } from '../../../../clients/providers/Organization';

const INITIAL_PAGE_NUMBER = 1;

const getSidebarClosed = (projectId: number): boolean =>
  JSON.parse(
    window.localStorage.getItem(`sidebar_project_${projectId}`) || '{}'
  )?.route === '/toggle';

const useDesignFeedback = ({
  page,
  project,
  asset,
  navigate,
  bugherdUrl,
  isMobileView
}: DesignFeedbackProps): DesignFeedbackState => {
  const imageRef: MutableRefObject<HTMLImageElement | null> = useRef(null);
  const canvasRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null);

  const [sidebarClosed, setSidebarClosed] = useState<boolean>(
    getSidebarClosed(project.id)
  );
  const [useOriginalSize, setUseOriginalSize] = useState<boolean>(true);
  const [fitToPage, setFitToPage] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(100);
  const [naturalWidth, setNaturalWidth] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(
    page ? Number(page) : INITIAL_PAGE_NUMBER
  );
  const [pdfScale, setPdfScale] = useState<number>(1);
  const [windowWidth, _setWindowWidth] = useState<number>(window.innerWidth);
  const [shareItem, setShareItem] = useState<number | string | null>(null);
  const { fileName, url, fileType } = camelcaseKeys(asset, { deep: true });
  const isPdf = fileType === 'pdf';
  const spacing = sidebarClosed || isMobileView ? 50 : 110;
  const availableWidth = windowWidth - spacing;
  const naturallyOverflow = naturalWidth > availableWidth;
  const naturalWidthPercentage = Math.round(
    (naturalWidth / availableWidth) * 100
  );

  const getPdfScale = () => {
    if (useOriginalSize) {
      return 1;
    } else if (!fitToPage) {
      return zoomLevel / 100;
    } else if (naturalWidth && naturallyOverflow) {
      return Math.round((availableWidth / naturalWidth) * 100) / 100;
    }
    return 1;
  };

  const setWindowWidth = () => _setWindowWidth(window.innerWidth);

  const getOverflowing = () => {
    if (fitToPage) return false;

    if (useOriginalSize) {
      return naturallyOverflow;
    } else if (isPdf) {
      // pdfScale dynamically changes the size of the <Document /> component from react-pdf relative to the naturalWidth
      return pdfScale * naturalWidth > availableWidth;
    }
    // zoomLevel === 100 will fill exactly the availableWidth for an image asset, thus zoomLevel > 100 is overflowing
    return zoomLevel > 100;
  };

  const [isOverflowing, setIsOverflowing] = useState<boolean>(getOverflowing());

  const handleKeyDown = event => {
    if (!event.key.includes('Arrow')) return;
    let newPage = currentPage + 1;
    if (event.key.includes('Left')) newPage = currentPage - 1;

    if (newPage && newPage <= totalPages) setCurrentPage(newPage);
  };

  const handleZoomIn = () => {
    if (useOriginalSize) setUseOriginalSize(false);
    if (fitToPage) setFitToPage(false);

    setZoomLevel(zoomLevel + 10);
  };

  const handleZoomOut = () => {
    if (useOriginalSize) setUseOriginalSize(false);
    if (fitToPage) setFitToPage(false);

    if (zoomLevel > 10) setZoomLevel(zoomLevel - 10);
  };

  const handleNavigation = ({ withZoom, scale }: NavParams = {}) => {
    const queryParams = [
      `page=${currentPage}`,
      withZoom && `zoom=${withZoom}`,
      scale && `scale=${scale}`
    ].filter(Boolean);

    navigate(`${window.location.pathname}?${queryParams.join('&')}`);
  };

  const handleLoadSuccess = async (success: DocumentCallback) => {
    setTotalPages(success.numPages);
    const pageObj = await success.getPage(currentPage);
    if (pageObj?.view) {
      const widthIndex = 2;
      setNaturalWidth(pageObj.view[widthIndex]);
    }
    handleNavigation();
  };

  const onImageLoad = () => {
    if (imageRef?.current) setNaturalWidth(imageRef?.current?.naturalWidth);
  };

  const onFitToPage = () => {
    if (useOriginalSize) setUseOriginalSize(false);
    setFitToPage(true);
  };

  const onUseOriginalSize = () => {
    if (fitToPage) setFitToPage(false);
    setUseOriginalSize(true);
  };

  let widthValue;
  if (useOriginalSize) {
    widthValue = `${naturalWidth}px`;
  } else if (fitToPage) {
    widthValue = '99%';
  } else {
    widthValue = `${zoomLevel}%`;
  }

  const inlineImageWidth = {
    width: widthValue
  };

  const updateScaleAndUrlAndOverflowing = () => {
    if (isPdf) {
      handleNavigation({
        withZoom: zoomLevel,
        scale: String(getPdfScale() * 100).slice(0, 3)
      });
      setPdfScale(getPdfScale());
    }
    setIsOverflowing(getOverflowing());
  };

  useEffect(() => {
    // @ts-expect-error
    window.setSidebarClosed = setSidebarClosed;
  }, []);

  const debounceFn = useCallback(debounce(setWindowWidth, 1000), []);

  useEffect(() => {
    window.addEventListener('resize', debounceFn);
    if (isPdf) {
      window.removeEventListener('keydown', handleKeyDown);
      window.addEventListener('keydown', handleKeyDown);

      handleNavigation();
    }

    return () => {
      if (isPdf) window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', debounceFn);
    };
  }, [currentPage]);

  useEffect(() => {
    updateScaleAndUrlAndOverflowing();
  }, [zoomLevel, windowWidth]);

  useEffect(() => {
    if (naturalWidth) {
      setZoomLevel(isPdf ? getPdfScale() * 100 : naturalWidthPercentage);
      if (naturalWidth > availableWidth) {
        onFitToPage();
      }
    }
    updateScaleAndUrlAndOverflowing();
  }, [naturalWidth]);

  useEffect(() => {
    const level =
      useOriginalSize &&
      (isPdf ? getPdfScale() * 100 : naturalWidth && naturalWidthPercentage);
    if (level) {
      setZoomLevel(level);
    } else {
      updateScaleAndUrlAndOverflowing();
    }
  }, [useOriginalSize]);

  useEffect(() => {
    const level = fitToPage && (isPdf ? getPdfScale() * 100 : 100);
    if (level) {
      setZoomLevel(level);
    } else {
      updateScaleAndUrlAndOverflowing();
    }
  }, [fitToPage]);

  const { hasOrganizationExperiment } = useOrganizationState();

  const onSharingClick = () => {
    handleSharingClick({
      assetId: asset.id,
      callback: setShareItem
    });
  };

  return {
    imageRef,
    canvasRef,
    sidebarClosed,
    useOriginalSize,
    onUseOriginalSize,
    currentPage,
    setCurrentPage,
    totalPages,
    fileName,
    isPdf,
    url,
    handleZoomIn,
    handleZoomOut,
    handleLoadSuccess,
    zoomLevel,
    setZoomLevel,
    fitToPage,
    onFitToPage,
    pdfScale,
    isOverflowing,
    inlineImageWidth,
    onImageLoad,
    shareItem,
    setShareItem,
    naturalWidth,
    onSharingClick
  };
};

export default useDesignFeedback;
