import React, { FC } from 'react';
import styles from './styles.module.css';
import {
  ConnectedIntegration,
  ParagonIntegrationData,
  ParagonIntegrations,
  WorkFlow,
  WebIntegration,
  HarvestIntegration
} from './types';
import {
  ParagonIntegration,
  Integration
} from '../../utils/getParagonIntegrations';
import { Button } from 'antd';
import * as translations from './strings';
import { getLangKey } from '../../models/Language';

const strings = translations[getLangKey()];

import Card from './Card';

type TaskConnectionData = {
  remoteTask: string;
  projectIntegrationId: number;
};

type Props = {
  taskConnections: ParagonIntegration;
  connectedIntegrations: ConnectedIntegration[];
  harvestIntegration: HarvestIntegration;
  container: HTMLDivElement;
  projectId: number;
  bugherdUrl: string;
  apiDomain?: string;
  isAdminView?: boolean;
  taskId: number;
  isSmallView?: boolean;
  paragonIntegrationsData: ParagonIntegrationData[];
  paragonIntegrations: ParagonIntegrations;
  webIntegrations?: WebIntegration[];
  loading: boolean;
  harvestEmbedUrl: string;
  organizationId: number;
  taskConnectionData: TaskConnectionData[];
};

const Integrations: FC<Props> = ({
  taskConnections,
  connectedIntegrations,
  harvestIntegration,
  harvestEmbedUrl,
  container,
  projectId,
  isSmallView,
  bugherdUrl,
  apiDomain,
  isAdminView,
  taskId,
  paragonIntegrationsData,
  paragonIntegrations,
  webIntegrations,
  loading,
  organizationId,
  taskConnectionData
}) => {
  const nativeIntegrationsData = () =>
    connectedIntegrations
      .filter(integration => integration.connector.code !== 'team')
      .map(integration => {
        const matchingTaskConnection = taskConnectionData?.find(
          task =>
            task.projectIntegrationId === integration?.projectIntegration.id
        );
        integration.remoteTaskId = matchingTaskConnection?.remoteTask || '';
        return integration;
      });

  const getLinkText = (projectName: string): string => {
    const match = projectName.match(/ (\()(.*?)(\))/g);
    const hasHash = projectName[0] === '#';
    return match
      ? (hasHash ? '' : '#') +
          projectName.slice(0, projectName.indexOf(match[0]))
      : projectName;
  };

  const paragonKeys = paragonIntegrations
    ? Object.keys(paragonIntegrations)
    : [];
  const taskConnectionKeys = taskConnections
    ? Object.keys(taskConnections)
    : [];

  const isQueryWorkflowEnabled = (integrationName: string): boolean =>
    !!paragonIntegrationsData?.find(({ workflows }: ParagonIntegrationData) => {
      const integration =
        paragonIntegrations && paragonIntegrations[integrationName];
      const _workflow = workflows.find((workflow: WorkFlow) =>
        workflow.description.toLowerCase().includes('link')
      );

      return (
        _workflow && integration?.configuredWorkflows[_workflow.id]?.enabled
      );
    });

  const getProjectName = (
    code: string,
    remoteProject: string,
    remoteProjectName: string
  ) => {
    if (code === 'slack') return getLinkText(remoteProjectName);
    if (code === 'github') return remoteProject;
    return remoteProjectName;
  };

  const harvestEnabled = !!harvestIntegration?.id;

  const showIntegrations =
    taskConnectionKeys.length ||
    paragonKeys.length ||
    connectedIntegrations.length ||
    webIntegrations?.length ||
    harvestEnabled;

  const handleShowSettings = () => {
    if (isAdminView) {
      // @ts-ignore
      window?.integration_settings_props?.setShow(true);
    } else {
      window.open(
        `${bugherdUrl}/projects/${projectId}/kanban?open=integration_settings`,
        '_blank'
      );
    }
  };

  type CardProps = {
    cardKey: string;
    integration?: Integration;
    error?: {
      code: number;
      message: string;
      created_at: string;
    };
    hasQueryWorkflow?: boolean;
    loading?: boolean;
    projectName?: string;
    showHelp?: boolean;
    harvestEmbedUrl?: string;
    projectIntegrationId?: number;
    integrationId?: number;
    organizationId: number;
    settings?: {};
    remoteTaskId?: string;
  };

  const IntegrationCard = (props: CardProps) => (
    <Card
      key={props.cardKey}
      name={props.cardKey}
      container={container}
      projectId={projectId}
      bugherdUrl={bugherdUrl}
      apiDomain={apiDomain}
      isAdminView={isAdminView}
      taskId={taskId}
      isSmallView={isSmallView}
      {...props}
    />
  );

  const webIntegrationCards = webIntegrations?.map(({ key, integration }) => (
    <IntegrationCard
      key={key}
      cardKey={key}
      integration={integration}
      showHelp
      organizationId={organizationId}
    />
  ));

  return showIntegrations ? (
    <div className={styles.integrationsOuter}>
      {taskConnectionKeys.map(key => (
        <IntegrationCard
          key={key}
          cardKey={key}
          integration={taskConnections[key]}
          error={taskConnections[key]?.error}
          organizationId={organizationId}
        />
      ))}
      {paragonKeys
        .filter(paragonKey => {
          const cleanParagonKey = paragonKey.replace(/^custom\./, '');
          return (
            paragonIntegrations &&
            (paragonIntegrations[paragonKey]?.enabled ||
              paragonIntegrations[cleanParagonKey]?.enabled) &&
            !taskConnectionKeys.includes(paragonKey) &&
            !taskConnectionKeys.includes(cleanParagonKey)
          );
        })
        .map(paragonKey => {
          const cleanParagonKey = paragonKey.replace(/^custom\./, '');
          return (
            <IntegrationCard
              key={paragonKey}
              cardKey={cleanParagonKey}
              hasQueryWorkflow={isQueryWorkflowEnabled(cleanParagonKey)}
              loading={loading}
              organizationId={organizationId}
            />
          );
        })}
      {nativeIntegrationsData().map(
        ({
          connector: { code },
          remoteTaskId,
          projectIntegration: {
            remoteProjectName,
            remoteProject,
            id,
            integrationId,
            settings
          }
        }) => (
          <IntegrationCard
            key={code}
            cardKey={code}
            projectName={getProjectName(code, remoteProject, remoteProjectName)}
            projectIntegrationId={id}
            integrationId={integrationId}
            organizationId={organizationId}
            settings={settings}
            remoteTaskId={remoteTaskId}
          />
        )
      )}
      {harvestEnabled && harvestEmbedUrl && (
        <IntegrationCard
          key={'harvest'}
          cardKey={'harvest'}
          harvestEmbedUrl={harvestEmbedUrl}
          organizationId={organizationId}
        />
      )}
      {webIntegrationCards}
    </div>
  ) : (
    <div className={styles.emptyState}>
      <div className={styles.emptyStateImage} />
      <span className={styles.firstIntegration}>
        {strings.firstIntegration}
      </span>
      <Button
        type="primary"
        onClick={handleShowSettings}
        className={styles.addIntegration}
      >
        {strings.addIntegration}
      </Button>
    </div>
  );
};

export default Integrations;
