import React, { FC, useEffect, useState } from 'react';
import { App, ConfigProvider } from 'antd';

import { antdTheme } from '../../../clients/shared/theme';
import { getCurrentProjectName, getCurrentProjectUrl } from 'models/Project';
import {
  getCurrentProjectId,
  getCurrentOrganizationId,
  getCurrentUserId
} from 'models/Organization';

import Cards from './Cards';
import Onboarding from './Onboarding';
import { KanbanProps as Props } from './types';
import ProjectTour from './ProjectTour';

import styles from '../../../clients/shared/overrides.module.css';
import { CurrentUserProvider } from '../../../../clients/providers/CurrentUser';
import { Provider } from 'react-redux';

const Kanban: FC<Props> = props => {
  const {
    showOnboarding,
    isJavascriptInstalled,
    kanbanLoading,
    isExtensionInstalled,
    widgetCode,
    siteErrors,
    lastSiteCheckAt,
    isTaskCreated,
    experiments,
    closeTask,
    setActiveTask,
    isGuest,
    viewGuestKanban,
    currentUserId
  } = props;

  const [isLegacyOnboardingComplete, setLegacyOnboardingComplete] = useState(
    false
  );

  const completedOnboarding =
    !showOnboarding || localStorage.getItem('finished_project_onboarding');
  const showOnboardingModal =
    !isGuest &&
    !kanbanLoading &&
    !(completedOnboarding || isLegacyOnboardingComplete);
  const showProjectTour =
    !isGuest && !kanbanLoading && !completedOnboarding && !showOnboardingModal;

  useEffect(() => {
    if (localStorage.getItem(`${currentUserId}_finished_onboarding_modal`)) {
      setLegacyOnboardingComplete(true);
    }
  }, []);

  const handleOnCloseModal = () => {
    setLegacyOnboardingComplete(true);
    localStorage.setItem(`${currentUserId}_finished_onboarding_modal`, 'true');
  };

  return (
    <CurrentUserProvider
      apiDomain={''}
      projectId={Number(getCurrentProjectId())}
    >
      <ConfigProvider theme={antdTheme}>
        <Provider store={window.bugherdStore}>
          <App className={styles.antdOverrides}>
            {(!isGuest || viewGuestKanban) && <Cards {...props} />}
            {/* Project tour for legacy & new onboarding */}
            {showProjectTour && (
              <ProjectTour
                projectId={getCurrentProjectId()}
                closeTask={closeTask}
                setActiveTask={setActiveTask}
              />
            )}
            {/* Legacy onboarding modal */}
            {showOnboardingModal && (
              <Onboarding
                showOnboarding={!completedOnboarding}
                isWebsiteVisited={isTaskCreated}
                isJavascriptInstalled={isJavascriptInstalled}
                projectName={getCurrentProjectName()}
                projectUrl={getCurrentProjectUrl()}
                projectId={getCurrentProjectId()}
                userId={getCurrentUserId()}
                organizationId={getCurrentOrganizationId()}
                isExtensionInstalled={isExtensionInstalled}
                widgetCode={widgetCode}
                siteErrors={siteErrors}
                lastSiteCheckAt={lastSiteCheckAt}
                experiments={experiments}
                onCloseModal={handleOnCloseModal}
              />
            )}
          </App>
        </Provider>
      </ConfigProvider>
    </CurrentUserProvider>
  );
};

export default Kanban;
