import React, { FC, useEffect, useState, useRef } from 'react';
import Tour from 'reactour';
import { Button, Typography } from 'antd';
import { post } from 'utils/fetch';
import useMediaQuery from '../../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import styles from './index.module.css';

const taskElement =
  '[data-intercom-target="Task"]:not([data-rbd-drag-handle-draggable-id="null"])';
const taskDetailsElement = '#task_details_container';
const shareButtonElement = '.shareButtonElement';

type Props = {
  projectId: number | null;
  closeTask: () => void;
  setActiveTask: (activeTask: number) => void;
};

type Step = {
  selector: string;
  content: React.JSX.Element;
  action?: (node: Node) => void;
  active: boolean;
  showButtons?: boolean;
};

const ProjectTour: FC<Props> = ({ projectId, closeTask, setActiveTask }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [tourStep, setTourStep] = useState(0);
  const [activeSteps, setActiveSteps] = useState<Step[]>([]);
  const isMobileView = useMediaQuery('(max-width: 640px)');
  const tourStepRef = useRef(tourStep);
  const activeStepsRef = useRef(activeSteps);

  useEffect(() => {
    tourStepRef.current = tourStep;
  }, [tourStep]);

  useEffect(() => {
    activeStepsRef.current = activeSteps;
  }, [activeSteps]);

  const goToNextTourStep = () => {
    // if last step, close the tour
    if (tourStepRef.current === activeStepsRef.current.length - 1) {
      setIsTourOpen(false);
      return;
    }
    setTourStep(prevTourStep => prevTourStep + 1);
  };
  const goToPrevTourStep = () => setTourStep(prevTourStep => prevTourStep - 1);

  const isAssetsPage = window.location.pathname.includes('assets');
  const isKanbanPage =
    window.location.pathname.includes('kanban') ||
    window.location.pathname.includes('tasks');

  const handleFinishTour = async () => {
    localStorage.setItem('finished_project_onboarding', 'true');
    try {
      await post(
        `/projects/${projectId}/complete_project_board_onboarding`,
        {}
      );
    } catch (error) {
      console.error({ error });
    }
  };

  const handleClickStep = node => {
    const handleOnClick = () => {
      goToNextTourStep();
      node.removeEventListener('click', handleOnClick);
    };
    node.addEventListener('click', handleOnClick);
  };

  const handleClickTask = node => {
    const handleShowTaskDetailsTour = e => {
      e.preventDefault();
      e.stopPropagation();
      setActiveTask(node.getAttribute('data-task-id'));
      goToNextTourStep();
      node.removeEventListener('click', handleShowTaskDetailsTour);
      node.removeEventListener('mousedown', handleShowTaskDetailsTour);
    };
    node.addEventListener('click', handleShowTaskDetailsTour);
    node.addEventListener('mousedown', handleShowTaskDetailsTour);
  };

  const getTourSteps = () => [
    {
      selector: taskElement,
      content: (
        <>
          <Typography.Title level={3} className={styles.tooltipHeader}>
            💡 Here's the feedback!
          </Typography.Title>
          <p>
            It's now a task in your project board and includes a screenshot and
            other metadata captured by BugHerd.
          </p>
          <p>
            <strong>Click it to see more.</strong>
          </p>
        </>
      ),
      action: handleClickTask,
      active: !!document.querySelector(taskElement) && isKanbanPage
    },
    {
      selector: taskDetailsElement,
      content: (
        <>
          <Typography.Title level={3} className={styles.tooltipHeader}>
            {' '}
            💡 BugHerd captured all the technical details for you
          </Typography.Title>
          <p>
            This includes a screenshot, the URL of the page, plus additional
            info like the browser detail as and operating system.
          </p>
          <div className={styles.nextButtonContainer}>
            <Button
              type="primary"
              onClick={() =>
                window.open(`/projects/${projectId}/assets`, '_self')
              }
            >
              Next
            </Button>
          </div>
        </>
      ),
      active: !!document.querySelector(taskDetailsElement) && isKanbanPage,
      showButtons: false
    },
    {
      selector: shareButtonElement,
      content: (
        <>
          <Typography.Title level={3} className={styles.tooltipHeader}>
            🎉 Share with your client when you are ready
          </Typography.Title>
          <p>Ready to share with your Client? Click 'Share'.</p>
          <p>Or click 'Finish Tour' to continue exploring BugHerd.</p>
        </>
      ),
      action: handleClickStep,
      active: !!document.querySelector(shareButtonElement) && isAssetsPage,
      showButtons: true
    }
  ];

  const initiateTour = () => {
    const initialActiveSteps = getTourSteps().filter(step => step.active);
    if (initialActiveSteps.length === 0) return;
    setActiveSteps(initialActiveSteps);
    setIsTourOpen(true);
  };

  const checkForElement = element => {
    const MAX_TRIES = 50;
    let tries = 0;

    const intervalId = setInterval(() => {
      if (tries >= MAX_TRIES) return clearInterval(intervalId);
      if (document.querySelector(element)) {
        initiateTour();
        clearInterval(intervalId);
      }
      tries++;
    }, 200);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    if ((!isKanbanPage && !isAssetsPage) || isMobileView) return;

    const elementToCheck = isAssetsPage ? shareButtonElement : taskElement;
    checkForElement(elementToCheck);
  }, []);

  useEffect(() => {
    if (!isTourOpen) return;

    const openConfetti = activeSteps.length === tourStep + 1;

    const initialActiveSteps = getTourSteps(openConfetti).filter(
      step => step.active
    );
    setActiveSteps(initialActiveSteps);
  }, [tourStep]);

  return (
    <Tour
      className="antd-container"
      steps={activeSteps}
      isOpen={isTourOpen}
      onRequestClose={() => setIsTourOpen(false)}
      accentColor="#3f71e0"
      closeWithMask={false}
      disableDotsNavigation
      goToStep={tourStep}
      lastStepNextButton={<Button type="primary">Finish Tour</Button>}
      rounded={12}
      scrollDuration={500}
      showNavigation={false}
      showCloseButton={false}
      showButtons={activeSteps[tourStep]?.showButtons || false}
      prevStep={goToPrevTourStep}
      onBeforeClose={handleFinishTour}
      nextButton={<Button type="primary">Next</Button>}
      prevButton={<></>}
      nextStep={() => {
        if (activeSteps[tourStep]?.selector === taskDetailsElement) {
          closeTask();
          goToNextTourStep();
        } else {
          goToNextTourStep();
        }
      }}
    />
  );
};

export default ProjectTour;
