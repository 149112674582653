import * as React from 'react';
import cx from 'classnames';

import Error from 'components/Error';
import RefreshMessage from 'components/RefreshMessage';
import { UserAccess } from 'models/Access';
import { getLangKey } from 'models/Language';
import { MemberRole } from 'models/MemberRole';
import { CurrentUser } from 'models/User';

import { useUserProjectsState } from './state';
import SearchAndAddProject from './SearchAndAddProject';
import ProjectCards from './ProjectCards';
import ProjectPagination from './ProjectPagination';
import EmptyState from './EmptyState';
import styles from './index.module.css';

import RedirectToCreateProject from './RedirectToCreateProject';
import * as translations from './strings';
import { ProjectSetupPendingBanner } from 'jsViews/user_projects/ProjectSetupPendingBanner';
import { AddFolderModal } from './AddFolderModal';
import { ProjectFolderBar } from './project_folder_bar';
import { ProjectFolderTour } from './ProjectFolderTour';
import { get } from 'appJS/utils/fetch';
import { ProjectHeader } from './ProjectHeader';
import { ProjectsFilterDropdown } from './ProjectsFilterDropdown';

type Props = {
  currentUser: CurrentUser;
  accessTo: UserAccess;
  disabled?: boolean;
  container: HTMLDivElement;
  hasUnreadCommentsExperiment: boolean;
  activeKey: string;
  setActiveKey: (key: string) => void;
  folder?: {
    id: string;
    subfolders?: { id: string; name: string }[];
    name: string;
  };
  favouriteProjectsTotal: number;
  selectedProjects: { id: string; name: string }[];
  setSelectedProjects: (
    selectedProjects: { id: string; name: string }[]
  ) => void;
  refetchCurrentUser: () => void;
  isMobileView: boolean;
  setShowAddFolder: (showAddFolder: boolean) => void;
  showAddFolder: boolean;
  setCurrentStep: (currentStep: number) => void;
  currentStep: number;
};

const UserProjects = ({
  currentUser,
  accessTo,
  disabled,
  container,
  hasUnreadCommentsExperiment,
  activeKey,
  setActiveKey,
  favouriteProjectsTotal,
  folder,
  selectedProjects,
  setSelectedProjects,
  refetchCurrentUser,
  setShowAddFolder,
  showAddFolder,
  setCurrentStep,
  currentStep,
  isMobileView
}: Props) => {
  const strings = translations[getLangKey()];
  const organization = currentUser.organization;
  const hasChecklistOnboardingExperiment = organization?.experiments?.includes(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = organization?.experiments?.includes(
    'add_website_onboarding_variant'
  );
  const hasAddWebsiteOnboardingNoProxyExperiment = organization?.experiments?.includes(
    'add_website_onboarding_no_proxy'
  );
  const hasCaptureUrlOnboardingExperiment = organization?.experiments?.includes(
    'capture_url_onboarding_variant'
  );
  const hasNewOnboardingExperiment =
    hasChecklistOnboardingExperiment ||
    hasAddWebsiteOnboardingExperiment ||
    hasAddWebsiteOnboardingNoProxyExperiment ||
    hasCaptureUrlOnboardingExperiment;

  const {
    pageNumber,
    showRefresh,
    setShowRefresh,
    searchByName,
    setSearchByName,
    sortBy,
    setSortBy,
    projectStatusFilter,
    setProjectStatusFilter,
    userProjects,
    userProjectsCount,
    userProjectsPageSize,
    onPaginationChange,
    getUserProjects: { data, loading, error, refetch }
  } = useUserProjectsState({
    organizationId: organization.id,
    activeKey,
    favouriteProjects: currentUser?.favouriteProjects
  });

  if (error) return <Error error={error} />;

  const emptyUserProjects =
    data &&
    searchByName === '' &&
    !activeKey &&
    data.currentUser.userProjectsCount === 0;

  if (
    currentUser.role === (MemberRole.BILLING_MANAGER || MemberRole.MANAGER) &&
    emptyUserProjects
  ) {
    return <RedirectToCreateProject organizationId={organization.id} />;
  }

  if (emptyUserProjects) {
    return <EmptyState owner={organization.owner} user={currentUser} />;
  }

  let pendingSetupProject;
  let noCompletedSetupProjects = true;
  let showSetupPendingBanner = false;

  if (hasNewOnboardingExperiment) {
    pendingSetupProject = userProjects.find(
      project => !project.hasCompletedChecklist
    );
    noCompletedSetupProjects = !userProjects.some(
      project => project.hasCompletedChecklist
    );
    showSetupPendingBanner = noCompletedSetupProjects && !!pendingSetupProject;
  }

  const {
    id: currentUserId,
    role,
    createdProjectFolder,
    addedProjectToFolder,
    completedProjectFolderTour,
    accessTo: { manageProjectFolders },
    organization: { id: organizationId, projectFoldersAllowed, projectFolders },
    favouriteProjects
  } = currentUser;

  return (
    <div className={cx(styles.wrapper, { [styles.disabled]: disabled })}>
      {showSetupPendingBanner && role === MemberRole.BILLING_MANAGER && (
        <ProjectSetupPendingBanner
          projectLink={`/projects/${pendingSetupProject?.id}/getting-started`}
        />
      )}
      <ProjectHeader
        folder={folder}
        activeKey={activeKey}
        manageProjectFolders={manageProjectFolders}
        organizationId={organizationId}
        refetchProjects={refetch}
        refecthCurrentUser={refetchCurrentUser}
        container={container}
      />

      <RefreshMessage
        show={showRefresh}
        message={strings.refreshPageMessage}
        onRefresh={() => {
          setShowRefresh(false);
          refetch();
        }}
      />

      <AddFolderModal
        setShowAddFolder={setShowAddFolder}
        showAddFolder={showAddFolder}
        projectFolders={projectFolders}
        organizationId={organizationId}
        refetchCurrentUser={refetchCurrentUser}
        container={container}
      />

      <ProjectFolderBar
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        projectFoldersAllowed={projectFoldersAllowed}
        projectFolders={projectFolders}
        organizationId={organizationId}
        container={container}
        refetchProjects={refetch}
        setCurrentStep={setCurrentStep}
        refetchCurrentUser={refetchCurrentUser}
      />

      <SearchAndAddProject
        organizationId={organizationId}
        searchByName={searchByName}
        setSearchByName={setSearchByName}
        showAddButton={
          accessTo.createProject && (!projectFoldersAllowed || isMobileView)
        }
      >
        <div className={styles.controlsContainer}>
          <ProjectPagination
            {...{
              pageNumber,
              userProjectsCount,
              userProjectsPageSize,
              onChange: onPaginationChange,
              position: 'top',
              simple: true
            }}
          />
          <ProjectsFilterDropdown
            sortBy={sortBy}
            projectStatusFilter={projectStatusFilter}
            projectFoldersAllowed={projectFoldersAllowed}
            isMobileView={isMobileView}
            activeKey={activeKey}
            projectFolders={projectFolders}
            setProjectStatusFilter={setProjectStatusFilter}
            setSortBy={setSortBy}
            setActiveKey={setActiveKey}
            favouriteProjectsTotal={favouriteProjectsTotal}
            isGuest={currentUser.role === 'guest'}
            container={container}
            toggleAddFolderModal={() => setShowAddFolder(!showAddFolder)}
          />
        </div>
      </SearchAndAddProject>

      <ProjectCards
        projects={userProjects}
        accessTo={accessTo}
        loading={loading}
        setShowRefresh={setShowRefresh}
        container={container}
        hasUnreadCommentsExperiment={hasUnreadCommentsExperiment}
        currentUserId={Number(currentUserId)}
        selectedProjects={selectedProjects}
        setSelectedProjects={setSelectedProjects}
        projectFoldersAllowed={projectFoldersAllowed}
        favouriteProjects={favouriteProjects}
        organizationId={organizationId}
        refetchCurrentUser={refetchCurrentUser}
        setCurrentStep={setCurrentStep}
        isGuest={currentUser.role === 'guest'}
      />

      <ProjectFolderTour
        createdProjectFolder={createdProjectFolder}
        addedProjectToFolder={addedProjectToFolder}
        completedProjectFolderTour={completedProjectFolderTour}
        onCancel={async () => {
          await get(
            `/organizations/${organizationId}/project_folders/completed_tour`
          );
          refetchCurrentUser();
        }}
        currentStep={currentStep}
      />

      <ProjectPagination
        {...{
          pageNumber,
          userProjectsCount,
          userProjectsPageSize,
          onChange: onPaginationChange,
          position: 'bottom',
          simple: false
        }}
      />
    </div>
  );
};

export default UserProjects;
