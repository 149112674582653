import React, { MouseEvent, MouseEventHandler, useState } from 'react';
import styles from './index.module.css';
import { DesignAsset } from './types';
import { formatDistanceStrict, parseISO } from 'date-fns';
import AssetMenu from './AssetMenu';
import TaskCount from './TaskCount';
import AssetName from './AssetName';
import AssetImage, { HoverShareButton } from './AssetImage';
import { Document, Page } from 'appJS/utils/pdfjs_worker';
import cx from 'classnames';
import Loader from '../sidebar/components/Loader';
// @ts-expect-error
import FigmaIcon from './assets/FigmaLogo';
import ShareButton from 'appJS/components/ShareButton';
import { UNGROUPED } from './AssetsScreen';
import * as translations from '../design_assets/strings';
import { getLangKey } from 'appJS/models/Language';
import { handleCopy } from 'appJS/utils/handleCopy';

const strings = translations[getLangKey()];

type Props = {
  asset: DesignAsset;
  container: HTMLDivElement;
  canManageAssets?: boolean;
  disabled?: boolean;
  setData?: (data: DesignAsset[]) => void;
  bugherdUrl: string;
  existingGroups?: string[];
  handleSharingClick?: () => void;
  onAddToGroup?: (groupName: string) => void;
  openNewGroupModal?: () => void;
  isGuest?: boolean;
  index?: number;
  useShareLink?: boolean;
  shareToken?: string;
  loggedIn?: boolean;
};

export const AssetCard: React.FC<Props> = ({
  asset,
  container,
  canManageAssets,
  disabled,
  existingGroups,
  setData,
  handleSharingClick,
  onAddToGroup,
  openNewGroupModal,
  isGuest,
  index,

  useShareLink,
  shareToken,
  loggedIn
}) => {
  const {
    id,
    name,
    fileType,
    url,
    projectId,
    openTasksCount,
    triageTasksCount,
    updatedAt,
    isFigma
  } = asset;
  const isPdf = fileType === 'pdf';
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const assetEndpoint = `/projects/${projectId}/assets/${id}`;
  const checkFileType = (isPdf && 'PDF') || (isFigma && 'FIGMA') || 'IMAGE';
  const showMenu = !!setData;

  const onShareButtonClick: MouseEventHandler<HTMLElement> = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (!showMenu) {
      const assetURL = new URL(window.location.href);
      assetURL.pathname = assetEndpoint;
      handleCopy({
        text: assetURL.href,
        successMessage: `URL for ${name} copied to clipboard ✅`
      });
    } else if (handleSharingClick) handleSharingClick();
  };

  const getCardAction = () => {
    const showMenu = !!setData;
    if (showMenu && !isGuest) {
      return (
        <AssetMenu
          container={container}
          assetEndpoint={assetEndpoint}
          onRenameClick={() => setIsEditing(true)}
          canManageAssets={!!canManageAssets}
          disabled={!!disabled}
          setData={setData}
          groupNames={
            existingGroups?.filter(group => group !== UNGROUPED) || []
          }
          asset={asset}
          handleSharingClick={() => handleSharingClick?.()}
          onAddToGroup={onAddToGroup}
          openNewGroupModal={openNewGroupModal}
        />
      );
    }
    return <ShareButton onClick={onShareButtonClick} iconOnly />;
  };

  return (
    <a
      href={
        useShareLink && shareToken
          ? `/share/${shareToken}`
          : `/projects/${projectId}/assets/${id}`
      }
      className={cx(styles.card, styles.staggerInAnimation, {
        [styles.guestCard]: !canManageAssets
      })}
      target="_self"
      style={{ '--index': index }}
    >
      <TaskCount
        container={container}
        openTasksCount={openTasksCount}
        triageTasksCount={triageTasksCount}
      />

      {isPdf ? (
        <>
          <Document
            file={url}
            loading={<Loader useDarkStyles className={styles.loaderIcon} />}
            className={cx(styles.pdfDocument, { [styles.grayscale]: disabled })}
          >
            <Page
              className={styles.pdfPage}
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              loading={<Loader useDarkStyles className={styles.loaderIcon} />}
            />
            <HoverShareButton
              onShareClick={onShareButtonClick}
              id={id}
              projectId={projectId}
              loggedIn={loggedIn}
            />
          </Document>
          {disabled && (
            <div className={styles.archived}>{strings.archived}</div>
          )}
        </>
      ) : (
        <AssetImage
          url={url}
          id={id}
          projectId={projectId}
          showShareHover
          disabled={!!disabled}
          onShareClick={onShareButtonClick}
          loggedIn={loggedIn}
        />
      )}
      <div className={styles.cardContent}>
        <AssetName
          assetEndpoint={assetEndpoint}
          name={name}
          fileType={fileType}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          canManageAssets={!!canManageAssets && !isGuest}
        />
        <p className={styles.lastUpdated}>
          Updated: {formatDistanceStrict(new Date(), parseISO(updatedAt))} ago
        </p>
        <div className={styles.cardFooter}>
          <p className={styles.typeInfo}>
            {isFigma && (
              <span className={styles.figmaIcon}>
                <FigmaIcon />
              </span>
            )}{' '}
            {checkFileType}
          </p>
          {getCardAction()}
        </div>
      </div>
    </a>
  );
};
