import React, { FC } from 'react';
import { View } from '../project_nav/types';

import { useOrganizationState } from '../../../../../clients/providers/Organization';
import GettingStartedChecklist from './GettingStartedChecklist';
import GettingStartedOld from './GettingStartedOld';

type Props = {
  project: any;
  setView: (view: View | null) => void;
  gettingStartedCheck: {
    hasSeenOnboardingVideo: boolean;
    hasExtensionOrScript: boolean;
    hasDeliverables: boolean;
    hasInvitedUsers: boolean;
    hasIntegrations: boolean;
  };
  canAccessBilling: boolean;
  handleChecklistComplete: () => void;
  showAssetsPage: () => void;
  container: HTMLDivElement;
  kanbanCount: number;
  proxyHost: string;
};

const GettingStarted: FC<Props> = ({
  project,
  setView,
  gettingStartedCheck,
  canAccessBilling,
  handleChecklistComplete,
  showAssetsPage,
  container,
  kanbanCount,
  proxyHost
}) => {
  return (
    <GettingStartedChecklist
      project={project}
      setView={setView}
      gettingStartedCheck={gettingStartedCheck}
      canAccessBilling={canAccessBilling}
      handleChecklistComplete={handleChecklistComplete}
      showAssetsPage={showAssetsPage}
      container={container}
      kanbanCount={kanbanCount}
      proxyHost={proxyHost}
    />
  );
};

export default GettingStarted;
