import React, { FC, useState } from 'react';
import Invite from '../../../components/invite';
import styles from './index.module.css';
import { Button, Divider, message, Typography } from 'antd';
import * as translations from './strings';
import { getLangKey } from 'appJS/models/Application';
import {
  CanAccess,
  OrganizationMemberUser,
  ShareProjectEndpoints
} from '../types';
import { GuestInvite } from './guest_invite';
import { FolderUp } from 'lucide-react';

const strings = translations[getLangKey()];

type Props = {
  authenticityToken: string;
  container: () => HTMLDivElement;
  endpoints: ShareProjectEndpoints;
  membersCount: number;
  membersMax: number;
  project: any;
  onInvitedUser: (user: OrganizationMemberUser) => void;
  onRemovedUser: (id: number) => void;
  onRegenerateInviteUrl: () => Promise<void>;
  onClearInviteUrl: () => Promise<void>;
  organization: {
    id: number;
    name: string;
  };
  role: string;
  users: OrganizationMemberUser[];
  usersOnProject: OrganizationMemberUser[];
  canAccess: CanAccess;
  shareLink: string;
  inviteUrl: string | null;
  isShareDeliverable: boolean;
  isGuest?: boolean;
  siteId?: number;
  assetId?: number;
  isShareSite: boolean;
  showAssetsPage: () => void;
};

export const ManageAccess: FC<Props> = ({
  onRegenerateInviteUrl,
  onClearInviteUrl,
  authenticityToken,
  container,
  endpoints,
  membersCount,
  membersMax,
  onInvitedUser,
  onRemovedUser,
  organization,
  project,
  role,
  users,
  canAccess,
  shareLink,
  usersOnProject,
  inviteUrl,
  isShareDeliverable,
  isGuest,
  siteId,
  assetId,
  isShareSite,
  showAssetsPage
}) => {
  const [loadingInviteUrl, setLoadingInviteUrl] = useState<boolean>(false);

  const generateInviteUrl = () => {
    message.info(strings.generateInviteUrl);
    setLoadingInviteUrl(true);
    onRegenerateInviteUrl()
      .then(() => {
        message.success(strings.generatedInviteUrl);
        setLoadingInviteUrl(false);
      })
      .catch(() => {
        message.error(strings.errorGeneratingInviteUrl);
        setLoadingInviteUrl(false);
      });
  };

  const disableInviteUrl = () => {
    message.info(strings.disablingInviteUrl);
    setLoadingInviteUrl(true);
    onClearInviteUrl()
      .then(() => {
        message.success(strings.disabledInviteUrl);
        setLoadingInviteUrl(false);
      })
      .catch(() => {
        message.error(strings.errorDisablingInviteUrl);
        setLoadingInviteUrl(false);
      });
  };

  return (
    <div className={styles.manageAccessContainer}>
      {!isGuest && (
        <Invite
          authenticityToken={authenticityToken}
          container={container}
          endpoint={{
            sendEmail: endpoints.addCollaborator,
            fetchUsers: endpoints.fetchUsers
          }}
          onInvitedUser={onInvitedUser}
          membersCount={membersCount}
          membersMax={membersMax}
          project={project}
          role={role}
          success={` ${strings.hasBeenInvited}${project.name}`}
          users={users.filter(
            user =>
              usersOnProject.filter(projectUser => projectUser.id === user.id)
                .length < 1
          )}
          collaboratorsEnabled={canAccess.collaborators}
          billingRights={canAccess.billing}
          orgId={organization.id}
          isShareDeliverable={isShareDeliverable}
          siteId={siteId}
          assetId={assetId}
        />
      )}
      {(isShareSite || isShareDeliverable) && (
        <GuestInvite
          shareLink={shareLink}
          inviteUrl={inviteUrl}
          loadingInviteUrl={loadingInviteUrl}
          disableInviteUrl={disableInviteUrl}
          generateInviteUrl={generateInviteUrl}
          projectDisabled={project.show_on_live === false}
          isShareDeliverable={isShareDeliverable}
          isGuest={isGuest}
        />
      )}
      {!isShareDeliverable && !isShareSite && (
        <ShareDeliverableBanner showAssetsPage={showAssetsPage} />
      )}
    </div>
  );
};

type BannerProps = {
  showAssetsPage: () => void;
};

const ShareDeliverableBanner: React.FC<BannerProps> = ({ showAssetsPage }) => (
  <>
    <Divider className={styles.divider}>OR</Divider>
    <div className={styles.shareDeliverableBanner}>
      <div className={styles.shareDeliverableBannerInfo}>
        <Typography.Title
          level={5}
          className={styles.shareDeliverableBannerHeader}
        >
          Share a website or files directly with a guest
        </Typography.Title>
        <p>Guests don't need to log in to view and give feedback.</p>
        <Button type="primary" icon={<FolderUp />} onClick={showAssetsPage}>
          Go to Websites & Files
        </Button>
      </div>
      <div className={styles.videoOuter}>
        <iframe
          src="https://player.vimeo.com/video/969558076?h=d37700eaf1"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          className={styles.video}
          title="Share your feedback on the new guest feedback experience"
        />
      </div>
    </div>
  </>
);
