import React, { FC, useState, useEffect, useImperativeHandle } from 'react';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import UseBugherdModal from './Sites/UseBugherdModal';
import styles from './index.module.css';
import { Button } from 'antd';
import track from 'jsUtilities/analytics';
import { fetchWithTimeout } from './util';
import { post } from 'appJS/utils/fetch';
import { ExternalLink } from 'lucide-react';
import { getProjectSite } from './Sites/ProjectSites';
import { useOrganizationState } from '../../../clients/providers/Organization';
import Tour from 'reactour';

export const checkIsCompatibleSite = async (
  proxyUrl: string,
  projectSiteUrl: string
) => {
  const compatibleEndpoint = `${proxyUrl}/compatibility?url=${projectSiteUrl}`;
  try {
    const response = await fetchWithTimeout(compatibleEndpoint);
    const { compatible } = await response.json();
    return !!compatible;
  } catch (error) {
    console.error(error);
    return false;
  }
};

type Step = {
  selector: string;
  content: React.JSX.Element;
  action?: (node: Node) => void;
  showButtons?: boolean;
  position: 'left' | 'right' | 'top' | 'bottom';
};

type Props = {
  proxyUrl: string;
  projectSiteId: number | string;
  projectSiteUrl: string;
  getTriggerElement: (loading: boolean) => React.ReactNode;
  project: any;
  apiKey?: string;
  onSharingClick?: () => void;
  showProxyPreview?: boolean;
  isGuest?: boolean;

  childRef?: any;
  showTour?: boolean;
};

const ProxyCompatibilityChecker: FC<Props> = ({
  proxyUrl,
  projectSiteId,
  projectSiteUrl,
  getTriggerElement,
  project,
  apiKey,
  showProxyPreview,
  isGuest,

  childRef,
  showTour
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [site, setSite] = useState<{ token: string | null; id: number } | null>(
    null
  );
  const [hasExtensionInstalled, setExtensionInstalled] = useState<boolean>(
    false
  );
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const { hasOrganizationExperiment } = useOrganizationState();

  const hasAddWebsiteOnboardingExperiment =
    hasOrganizationExperiment('add_website_onboarding_variant') ||
    hasOrganizationExperiment('add_website_onboarding_no_proxy') ||
    hasOrganizationExperiment('capture_url_onboarding_variant');

  const container = document.body;

  const { id: projectId, showAddWebsiteOnboarding } = project || {};

  const hasJsScript = site?.events?.includes('site_js_verified') || false;

  let isProxyCompatible = false;

  const tourSteps: Step[] = [
    {
      selector: '.projectSiteCard',
      content: (
        <>
          <h2> 💡 You’re ready to use BugHerd on this website.</h2>
          <p>Click ‘Open website’ to get started.</p>
        </>
      ),
      action: node => {
        node.focus();
        setIsTourOpen(true);
      },
      position: 'right'
    }
  ];
  const completeAddWebsiteOnboarding = async () => {
    try {
      await post(`/projects/${projectId}/complete_add_website_onboarding`, {
        project_id: project.id
      });
      localStorage.setItem('completed_add_website_onboarding', 'true');
    } catch (error) {
      console.error(error);
    } finally {
      setIsTourOpen(false);
    }
  };

  const checkCompatibility = async () => {
    if (!projectSiteUrl) return;
    isProxyCompatible = await checkIsCompatibleSite(proxyUrl, projectSiteUrl);
    return isProxyCompatible;
  };

  const trackSiteOpening = () => {
    track('Opened Project Site', {
      viaProxy: false,
      viaExtension: hasExtensionInstalled,
      viaScriptInstalled: hasJsScript
    });
  };

  const navigateToProxy = async () => {
    const endpoint = `/projects/${projectId}/sites/${projectSiteId}?apikey=${project?.apiKey ||
      apiKey}`;
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error(`An error has occurred: ${response.status}`);
    }

    track('Opened Project Site', { viaProxy: true });
    window.location.href = endpoint;
  };

  const openWebsite = () => {
    trackSiteOpening();
    if (isTourOpen) completeAddWebsiteOnboarding();
    window.open(projectSiteUrl, '_blank');
  };

  const initAndOpenShareURL = async () => {
    try {
      let token;
      if (site && site.token) {
        token = site.token;
      } else {
        const response = await post(`/share_tokens`, {
          project_site_id: projectSiteId,
          asset_id: null
        });
        token = response.token;
      }

      if (token) window.open(`/share/${token}`, '_blank');
    } catch (error) {
      console.error('Error generating share token:', error);
    }
  };

  const handleTriggerClick = async () => {
    if (isGuest) {
      return await initAndOpenShareURL();
    }

    if (!showProxyPreview) {
      return openWebsite();
    }

    setIsLoading(true);
    await checkCompatibility();

    if (!isProxyCompatible) {
      setIsLoading(false);
      return openWebsite();
    }

    try {
      await navigateToProxy();
      if (isTourOpen) completeAddWebsiteOnboarding();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  //used so the project site card can use the trigger click
  useImperativeHandle(childRef, () => ({
    handleTriggerClick: (e: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      handleTriggerClick();
    }
  }));

  useEffect(() => {
    if (isLoading || !showTour || !hasAddWebsiteOnboardingExperiment) return;
    const hasCompletedTour =
      localStorage.getItem('completed_add_website_onboarding') === 'true' ||
      !showAddWebsiteOnboarding;

    setIsTourOpen(!hasCompletedTour);
  }, [showAddWebsiteOnboarding, hasAddWebsiteOnboardingExperiment, isLoading]);

  useEffect(() => {
    (async () => {
      if (!projectSiteId) return;
      const response = await getProjectSite(projectId, String(projectSiteId));
      setSite(response?.project_site);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const exists = await checkForExtension();
        if (exists) {
          setExtensionInstalled(exists);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [site]);

  const renderButton = () => {
    let buttonText = 'Open website';
    let icon = <ExternalLink size={16} />;
    let type: 'primary' | 'default' = 'primary';
    let className = styles.guestButton;

    if (!isGuest) {
      buttonText = 'Open website';
      type = 'primary';
      className = '';
    }

    return (
      <Button
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleTriggerClick();
        }}
        type={type}
        icon={icon}
        loading={isLoading}
        className={className}
        disabled={isLoading}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <div className={styles.getTriggerElement} onClick={handleTriggerClick}>
        {getTriggerElement(isLoading)}
      </div>
      {!getTriggerElement(isLoading) && renderButton()}
      <UseBugherdModal
        container={container}
        isUseBugherdModalOpen={showInstallModal}
        handleCloseUseBugherdModal={() => {
          setShowInstallModal(false);
          window.location.reload();
        }}
        url={projectSiteUrl}
        isGuest={isGuest}
      />
      <Tour
        steps={tourSteps}
        isOpen={isTourOpen}
        accentColor="#3f71e0"
        closeWithMask={false}
        disableInteraction={false}
        disableDotsNavigation
        disableFocusLock
        lastStepNextButton={<Button type="primary">Finish</Button>}
        rounded={12}
        scrollDuration={500}
        showNavigation={false}
        showCloseButton={false}
        showNumber={false}
        showButtons={false}
      />
    </>
  );
};

export default ProxyCompatibilityChecker;
