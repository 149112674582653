import React, { FC, useState } from 'react';
import { ChevronRight, Filter } from 'lucide-react';
import styles from './index.module.css';
import { Button, Dropdown, Tooltip, message } from 'antd';
import { filterMenuItems } from './filterMenuItems';
import { Folder } from './project_folders';
import { ProjectStatusFilter } from './state';

type Props = {
  sortBy: string;
  projectStatusFilter: ProjectStatusFilter;
  projectFoldersAllowed: boolean;
  isMobileView: boolean;
  activeKey: string;
  projectFolders: Folder[];
  setProjectStatusFilter: (projectStatusFilter: ProjectStatusFilter) => void;
  setSortBy: (sortBy: string) => void;
  setActiveKey: (activeKey: string) => void;
  favouriteProjectsTotal: number;
  isGuest: boolean;
  container: HTMLDivElement;
  toggleAddFolderModal: () => void;
};

export const ProjectsFilterDropdown: FC<Props> = ({
  sortBy,
  projectStatusFilter,
  projectFoldersAllowed,
  isMobileView,
  activeKey,
  projectFolders,
  setProjectStatusFilter,
  setSortBy,
  setActiveKey,
  favouriteProjectsTotal,
  isGuest,
  container,
  toggleAddFolderModal
}) => {
  return (
    <Tooltip title="Sort & Filter Projects">
      <Dropdown
        trigger={['click']}
        menu={{
          items: filterMenuItems({
            sortBy,
            projectStatusFilter,
            projectFoldersAllowed,
            isMobileView,
            isGuest,
            activeKey,
            projectFolders
          }),
          mode: 'vertical',
          expandIcon: <ChevronRight className={styles.expandIcon} />,
          triggerSubMenuAction: 'click',
          onOpenChange: openKeys => {
            if (openKeys[0]) setActiveKey(openKeys[0]);
          },
          onClick: ({ key }) => {
            if (key === 'name' || key === 'updated_at') {
              setSortBy(key);
            } else if (projectStatusFilter !== key && (key === 'active' || key === 'inactive' || key === 'all')) {
              // @ts-expect-error
              setProjectStatusFilter(key);
            } else if (key === 'favourites') {
              if (!favouriteProjectsTotal) {
                message.warning(
                  "You don't have any favourite projects, look for the ⭐️ on the project card to add some.",
                  4
                );
                setActiveKey('all-projects');
              } else {
                setActiveKey('favourites');
              }
            } else if (key === 'all-projects') {
              setActiveKey('all-projects');
            } else if (key === 'new-folder') {
              toggleAddFolderModal();
            } else {
              setActiveKey(key);
            }
          }
        }}
        getPopupContainer={() => container as HTMLDivElement}
        overlayClassName={styles.sortOverlay}
        placement="bottom"
        className={styles.sortBy}
      >
        <Button
          type="default"
          shape="round"
          icon={<Filter className={styles.filterIcon} />}
        />
      </Dropdown>
    </Tooltip>
  );
};
