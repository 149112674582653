import { ProjectSite } from 'models/Project';

export interface Project {
  id: string;
  name: string;
  devurl?: string;
  hostnames: string[];
  allowEmailTasks: boolean;
  allowEmailComments: boolean;
  isPublic: boolean;
  organizationId: string;
  apiKey: string;
  guestPermission: string;
  sites: ProjectSite[];
  ignoredQuerystrings: string[];
  allowProjectSummaryEmail: boolean;
  allowProjectOwnerNotifications: boolean;
  allowTaskDoneEmail: boolean;
  ownerId: string;
  owner: {
    id: string;
    email: string;
    name: string;
  };
  membersOnProject: {
    id: string;
    email: string;
    name: string;
  }[];
}

export type ProjectSiteData = {
  project?: {
    id: string;
    feedbackUrl?: string;
  };
  projectSite?: { id: string };
};

export enum ProjectFormField {
  AllowGuestPermission = 'allowGuestPermission',
  AllowEmailTasks = 'allowEmailTasks',
  AllowEmailComments = 'allowEmailComments',
  AllowProjectOwnerNotifications = 'allowProjectOwnerNotifications',
  OwnerId = 'ownerId',
  Name = 'name',
  IgnoreList = 'ignoredQuerystrings'
}
