import React, { useState, useEffect } from 'react';
import styles from './teamworkSettings.module.css';
import { get, post } from '../../utils/fetch';
import { Form, Row, Col, Button, Select, Tooltip, Checkbox, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';

interface Props {
  projectId: string;
  organizationId: string;
  integration:
    | {
        enabled: boolean;
        id: string;
        settings: {
          site_name: string;
          task_list: Object;
          project: Object;
          new_tasks: boolean;
          new_comments: boolean;
          sync_teamwork_comments: boolean;
          teamwork_comment_webhook_id: string;
          sync_teamwork_task_completion: boolean;
          teamwork_task_completion_webhook_id: string;
          done_column: string;
          task_status: Object;
        };
      }
    | undefined;
}

const TeamworkSettings: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();

  const { integration, organizationId, projectId } = props;
  const [projectIntegrationId, setProjectIntegrationId] = useState('');
  const [selectedSiteName, setSelectedSiteName] = useState(
    integration?.settings?.site_name
  );
  const [selectedProject, setSelectedProject] = useState(
    integration?.settings?.project
  );
  const [selectedTaskList, setSelectedTaskList] = useState(
    integration?.settings?.task_list
  );
  const [selectedStatus, setSelectedStatus] = useState(
    integration?.settings?.task_status
  );
  const [autoPost, setAutoPost] = useState(
    integration?.settings?.new_tasks.toString() === 'true'
  );
  const [newComments, setNewComments] = useState(
    integration?.settings?.new_comments.toString() === 'true'
  );
  const [syncCommentsToTeamwork, setSyncCommentsToTeamwork] = useState(
    integration?.settings?.sync_teamwork_comments.toString() === 'true'
  );
  const [commentWebhookId, setCommentWebhookId] = useState(
    integration?.settings?.teamwork_comment_webhook_id
  );
  const [syncTaskCompletion, setSyncTaskCompletion] = useState(
    integration?.settings?.sync_teamwork_task_completion.toString() === 'true'
  );
  const [taskCompletionWebhookId, setTaskCompletionWebhookId] = useState(
    integration?.settings?.teamwork_task_completion_webhook_id
  );
  const [doneColumn, setDoneColumn] = useState(
    integration?.settings?.done_column
  );
  const [remoteProjectOptions, setRemoteProjectOptions] = useState([]);
  const [remoteTaskListOptions, setTaskListOptions] = useState([]);
  const [remoteStatusOptions, setRemoteStatusOptions] = useState([]);

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const handleProjectSelect = (value: string, label: Object) => {
    setSelectedProject(label);
    getTaskList(value);
  };

  const handleTaskListSelect = (value: string, label: Object) => {
    setSelectedTaskList(label);
  };

  const handleStatusSelect = (value: string, label: Object) => {
    setSelectedStatus(label);
  };

  const handleSiteNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteName = e.target.value;
    setSelectedSiteName(siteName);

    if (siteName) {
      getTeamOptions(siteName);
    }
  };

  const handleSiteNameChangeDebounced = debounce(handleSiteNameChange, 2000);

  const getConnectedState = async () => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/integration_connection?project_id=${projectId}&integration_code=Teamwork`
      );
      setProjectIntegrationId(results.project_integration_id);
      setSelectedProject(results.settings.project);
      setSelectedSiteName(results.settings.site_name);
      setAutoPost(results.settings.new_tasks.toString() === 'true');
      setNewComments(results.settings.new_comments.toString() === 'true');
      setSyncCommentsToTeamwork(
        results.settings.sync_teamwork_comments.toString() === 'true'
      );
      setCommentWebhookId(results.settings.teamwork_comment_webhook_id);
      setSyncTaskCompletion(
        results.settings.sync_teamwork_task_completion.toString() === 'true'
      );
      setTaskCompletionWebhookId(
        results.settings.teamwork_task_completion_webhook_id
      );
      setDoneColumn(results.settings.done_column);
      setSelectedStatus(results.settings.task_status);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const getTeamOptions = async (siteName?: string) => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/remote_projects?id=${
          integration.id
        }&site_name=${siteName || selectedSiteName}`
      );
      setRemoteProjectOptions(results);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const getTaskList = async projectId => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/teamwork_task_lists?id=${
          integration.id
        }&site_name=${selectedSiteName}&project_id=${projectId ||
          selectedProject.value}`
      );
      setTaskListOptions(results);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const getStatusColumns = async projectId => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(`/projects/full?id=${projectId}`);

      const columns = [
        { id: null, value: '' },
        ...results.columns.map(column => ({
          id: column.id,
          value: column.name
        }))
      ];
      setRemoteStatusOptions(columns);
    } catch (error) {
      message.error('something went wrong, please try again.');
    }
  };

  const checkWebhooks = async () => {
    try {
      await post(
        `/organizations/${organizationId}/integrations/${integration.id}/project_integrations/handle_webhooks`,
        {
          project_id: projectId,
          site_name: selectedSiteName,
          remote_project_id: selectedProject.value,
          project_integration_id: projectIntegrationId
        }
      );
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    getConnectedState();

    if (selectedSiteName) {
      getTeamOptions(selectedSiteName);
    }

    getStatusColumns(projectId);

    if (selectedProject) {
      getTaskList();
    }
  }, []);

  const saveSettings = async () => {
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/integration_connection?project_id=${projectId}&integration_code=Teamwork`
      );
      setCommentWebhookId(results.settings.teamwork_comment_webhook_id);
      setTaskCompletionWebhookId(
        results.settings.teamwork_task_completion_webhook_id
      );
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }

    try {
      await post(
        `/organizations/${organizationId}/integrations/${integration.id}/project_integrations/save_settings?integration_code=Teamwork`,
        {
          project_id: projectId,
          project_integration_id: projectIntegrationId,
          settings: {
            site_name: selectedSiteName,
            project: selectedProject,
            task_list: selectedTaskList,
            new_tasks: autoPost,
            new_comments: newComments,
            sync_teamwork_comments: syncCommentsToTeamwork,
            teamwork_comment_webhook_id: commentWebhookId,
            sync_teamwork_task_completion: syncTaskCompletion,
            teamwork_task_completion_webhook_id: taskCompletionWebhookId,
            done_column: doneColumn,
            task_status: selectedStatus
          }
        }
      );
      checkWebhooks();
      message.success('Settings saved', 5);
      window.location.reload();
    } catch (error) {
      console.error('Error saving settings', error);
      message.error('Something went wrong, please try again.');
    }
  };

  const handleDisconnect = async () => {
    try {
      await post(
        `/organizations/${organizationId}/integrations/${integration.id}/project_integrations/disconnect`,
        { id: projectIntegrationId }
      );
      message.success('Integration disconnected', 5);
    } catch (error) {
      console.error('Error disconnecting integration', error);
      message.error('Something went wrong, please try again.');
    }
    window.location.reload();
  };

  const renderConnect = () => {
    return (
      <div className={styles.teamworkExplanation}>
        <p>
          Connect BugHerd with your Teamwork account to sync your tasks and
          comments. For example:
        </p>
        <ul>
          <li>Post tasks to your Teamwork kanban board</li>
          <li>Sync new status updates to your connected tasks</li>
          <li>Sync comments with teamwork</li>
        </ul>
        <a
          className="btn ant-btn-primary"
          rel="nofollow"
          data-method="post"
          href={`/auth/teamwork?project=${projectId}&organization=${organizationId}&type=Teamwork`}
        >
          <span className={styles.buttonText}>Connect</span>
        </a>
      </div>
    );
  };

  const renderIntegrationSettings = () => {
    return (
      <div>
        <p className={styles.teamworkChannelHeader}>
          Teamwork
          <span className={styles.infoContainer}>
            <Tooltip title="Choose the team and channel you want to sync with BugHerd">
              <InfoCircleOutlined style={{ color: '#3F71E0' }} />
            </Tooltip>
          </span>
        </p>
        <Form form={form} layout="vertical" onFinish={saveSettings}>
          <Row className={styles.teamworkEditRow}>
            <Col span={10}>
              <Form.Item
                name="sitename"
                label="Site Name"
                rules={[{ required: true }]}
                initialValue={selectedSiteName}
              >
                <Input
                  value={selectedSiteName}
                  onChange={handleSiteNameChangeDebounced}
                  placeholder="Site Name"
                />
              </Form.Item>
            </Col>
            <Col span={10} className={styles.teamworkDomain}>
              .teamwork.com
            </Col>
          </Row>
          <Row className={styles.teamworkEditRow}>
            <Col span={10}>
              <Form.Item
                name="project"
                label="Project"
                rules={[{ required: true }]}
                initialValue={selectedProject}
              >
                <Select
                  options={remoteProjectOptions}
                  onSelect={handleProjectSelect}
                  loading={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.teamworkEditRow}>
            <Col span={10}>
              <Form.Item
                name="tasklist"
                label="Task List"
                rules={[{ required: true }]}
                initialValue={selectedTaskList}
              >
                <Select
                  options={remoteTaskListOptions}
                  onSelect={handleTaskListSelect}
                  loading={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.teamworkWorkflows}>
            <Row className={styles.teamworkEditRow}>
              <Form.Item>
                <Checkbox
                  checked={autoPost}
                  onChange={e => setAutoPost(e.target.checked)}
                >
                  Automatically post new tasks to Teamwork
                </Checkbox>
              </Form.Item>
              {autoPost && (
                <Form.Item
                  name="statusColumn"
                  label="Create task when status is equal to"
                  rules={[{ required: false }]}
                  initialValue={selectedStatus}
                  tooltip="This is an optional field"
                >
                  <Select
                    options={remoteStatusOptions}
                    onSelect={handleStatusSelect}
                    loading={false}
                  />
                </Form.Item>
              )}
            </Row>
            <Row className={styles.teamworkEditRow}>
              <Form.Item>
                <Checkbox
                  checked={newComments}
                  onChange={e => setNewComments(e.target.checked)}
                >
                  Automatically post new comments to Teamwork
                </Checkbox>
              </Form.Item>
            </Row>
            <Row className={styles.teamworkEditRow}>
              <Form.Item>
                <Checkbox
                  checked={syncCommentsToTeamwork}
                  onChange={e => setSyncCommentsToTeamwork(e.target.checked)}
                >
                  Sync comments from Teamwork
                </Checkbox>
              </Form.Item>
            </Row>
            <Row className={styles.teamworkEditRow}>
              <Form.Item>
                <Checkbox
                  checked={syncTaskCompletion}
                  onChange={e => setSyncTaskCompletion(e.target.checked)}
                >
                  Update BugHerd status when Completed in Teamwork
                  <span className={styles.infoContainer}>
                    <Tooltip title="When a task is completed in Teamwork, move it to a particular BugHerd column. If you'd like the BugHerd task to be archived, set this column as 'archive'.">
                      <InfoCircleOutlined style={{ color: '#3F71E0' }} />
                    </Tooltip>
                  </span>
                </Checkbox>
                {syncTaskCompletion && (
                  <>
                    <div className={styles.completedColumn}>
                      Completed column in BugHerd:
                    </div>
                    <Input
                      value={doneColumn}
                      onChange={e => setDoneColumn(e.target.value)}
                      placeholder=""
                      required
                    />
                  </>
                )}
              </Form.Item>
            </Row>
          </div>
          <Row className={styles.teamworkEditRow}>
            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="default"
                  htmlType="submit"
                  className={styles.save}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={styles.disconnectContainer}>
          <span>Disconnect integration</span>
          <Button
            className={styles.disconnectButton}
            onClick={() => handleDisconnect()}
            type="text"
          >
            Disconnect
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {integration?.enabled ? renderIntegrationSettings() : renderConnect()}
    </div>
  );
};

export default (props: Props) => <TeamworkSettings {...props} />;
