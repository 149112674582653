import React, { useRef, useState } from 'react';
import { Alert } from 'antd';
import styles from './index.module.css';
import { post } from 'utils/fetch';
import track from 'utils/analytics';
import { HarvestIntegration } from 'appJS/components/Integrations/types';
import { useParagonIntegrationsState } from '../../../../clients/providers/ParagonIntegrations';
import Hint from 'components/Hint';
import { IntegrationItem, premiumIntegrations } from './integrationItem';
import { Image } from 'antd';

interface Props {
  project: {
    organizationId: string;
    id: string;
  };
  zapier: {
    id: string;
  };
  legacyIntegrations: object;
  paragonProjectId: string | null;
  paragonAllowed: boolean;
  editingAllowed: boolean;
  bugherdUrl: string;
  ownerEmail: string;
  harvestIntegration: HarvestIntegration;
  trialUser: boolean;
  switchModal: Function;
  connectors: object[];
  currentUser: {
    id: string;
    name: string;
    email: string;
    viewedGuestProjectBoard: boolean;
    role: string;
  };
}

declare var paragon: any;

const ParagonSettings: React.FC<Props> = ({
  paragonAllowed,
  project,
  legacyIntegrations: _legacyIntegrations,
  switchModal,
  zapier,
  connectors,
  harvestIntegration,
  ownerEmail,
  bugherdUrl,
  trialUser,
  editingAllowed,
  currentUser
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const integrationsWithModals = ['webhooks', 'harvest', 'teams', 'teamwork'];
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);
  const [popOverToShow, setPopOverToShow] = useState<string>('');
  const [showPreview, setShowPreview] = useState<string>('');
  const {
    paragonIntegrations,
    setParagonIntegrations
  } = useParagonIntegrationsState();

  const enabledIntegrations = (integrations: any) => {
    return Object.keys(integrations || [])
      .sort()
      .reduce((filtered: string[], integrationName) => {
        const integration = integrations[integrationName];
        const isPremiumIntegration = premiumIntegrations.includes(
          integrationName
        );
        if (integration.enabled) {
          if (!isPremiumIntegration || paragonAllowed) {
            filtered.push(integrationName);
          }
        }
        return filtered;
      }, []);
  };

  const updateParagonEnabled = async (enabled: boolean) => {
    const projectId = project.id;

    await post(`/projects/${projectId}/update_paragon_status`, { enabled });
  };

  const paragonEnabledState = async () => {
    const ints = Object.assign({}, paragon.getUser().integrations);
    const enabled = enabledIntegrations(ints).filter(
      integration => integration
    );

    setParagonIntegrations(ints);

    if (enabled.length > 0) {
      await updateParagonEnabled(true);
    } else if (enabled.length < 1) {
      await updateParagonEnabled(false);
    }
  };

  const connectIntegration = async (integration: any) => {
    track('Integration connected', {
      integration: integration.integrationType,
      project_id: project.id
    });

    await paragonEnabledState();
  };

  const disconnectIntegration = async (integration: any) => {
    track('Integration disconnected', {
      integration: integration.integrationType,
      project_id: project.id
    });

    await paragonEnabledState();
  };

  const showIntegration = async (
    integration: string,
    paragonIntegration: boolean
  ) => {
    const legacyIntegrations: any = _legacyIntegrations;

    if (paragonIntegration) {
      paragon.connect(integration, {
        onSuccess: (event: any) => {
          connectIntegration(event);
        },
        onUninstall: (event: any) => {
          disconnectIntegration(event);
        }
      });
    } else if (integrationsWithModals.includes(integration)) {
      switchModal(integration);
      return;
    } else if (
      legacyIntegrations[integration] &&
      legacyIntegrations[integration].enabled
    ) {
      window.location.pathname = `organizations/${project.organizationId}/integrations/${legacyIntegrations[integration].id}/edit`;
    } else if (integration === 'zapier' && zapier.id) {
      window.location.pathname = `settings/organizations/${project.organizationId}/integrations/${zapier.id}/edit`;
    } else {
      window.location.pathname = `organizations/${project.organizationId}/integrations/new/${integration}`;
    }
  };

  const integrations = () => {
    const integrations: any = _legacyIntegrations;
    const integrationList = {
      basecamp: {
        id: integrations['basecamp'] && integrations['basecamp'].id,
        enabled: !!integrations['basecamp']
      },
      github: {
        id: integrations['github'] && integrations['github'].id,
        enabled: !!integrations['github']
      },
      slack: {
        id: integrations['slack'] && integrations['slack'].id,
        enabled: !!integrations['slack']
      },
      zapier: {
        id: integrations['zapier'] && integrations['zapier'].id,
        enabled: !!integrations['zapier']
      },
      webhooks: { id: 'webhooks', enabled: true },
      teams: { id: 'teams', enabled: !!integrations['team'] },
      teamwork: { id: 'teamwork', enabled: !!integrations['teamwork'] },
      trello: { id: 'trello', enabled: false, loaded: false },
      linear: { id: 'linear', enabled: false, loaded: false },
      asana: { id: 'asana', enabled: false, loaded: false },
      jira: { id: 'jira', enabled: false, loaded: false },
      clickup: { id: 'clickup', enabled: false, loaded: false }
    };
    // add harvest integration if connector exists
    if (connectors.find(connector => connector['code'] === 'harvest')) {
      return {
        ...integrationList,
        harvest: {
          id: integrations['harvest'] && integrations['harvest'].id,
          enabled: !!harvestIntegration.id
        }
      };
    }
    return integrationList;
  };

  const integrationList = { ...integrations(), ...paragonIntegrations };

  return (
    <div>
      {showPreview && (
        <Image
          src={showPreview}
          style={{ display: 'none' }}
          preview={{
            src: showPreview,
            getContainer: () =>
              document.getElementById('settingsBoard') as HTMLDivElement,
            visible: !!showPreview,
            onVisibleChange: visible => {
              if (!visible) setShowPreview('');
            },
            toolbarRender: (_, info) => null,
            imageRender: () => {
              const isYoutube = showPreview.includes('youtu.be');
              const isVimeo = showPreview.includes('vimeo');
              const url = new URL(showPreview);
              const id = url.pathname.split('/').pop();
              url.search = '';
              if (!isVimeo) url.pathname = `/embed/${id}`;
              if (isYoutube) url.hostname = 'www.youtube.com';
              return (
                <iframe
                  src={url.href}
                  style={{ border: 'none' }}
                  width="90%"
                  height={isYoutube ? '80%' : '68%'}
                  allowFullScreen
                />
              );
            }
          }}
        />
      )}
      <div ref={modalRef} className={styles.container}>
        {!editingAllowed && (
          <Alert
            showIcon
            type="info"
            className={styles.alert}
            message="Some integrations require “Manager” permissions to configure."
          />
        )}
        <ul>
          {Object.keys(integrationList)
            .sort()
            .map((integrationName, index) => (
              <IntegrationItem
                key={integrationName}
                name={integrationName}
                paragonAllowed={paragonAllowed}
                isTrialUser={trialUser}
                isParagonIntegration={Object.keys(paragonIntegrations).includes(
                  integrationName
                )}
                integrationList={integrationList}
                handleShowIntegration={showIntegration}
                handleShowUpgrade={() => {
                  setShowUpgrade(!showUpgrade);
                  setPopOverToShow(integrationName);
                }}
                // @ts-expect-error
                role={currentUser.role}
                editingAllowed={editingAllowed}
                setPopOverToShow={setPopOverToShow}
                popOverToShow={popOverToShow}
                ownerEmail={ownerEmail}
                bugherdUrl={bugherdUrl}
                organizationId={project.organizationId}
                setShowPreview={setShowPreview}
                index={index}
              />
            ))}
        </ul>
      </div>
      {currentUser && (
        <Hint
          message={
            <div className={styles.suggestIntegrationTooltip}>
              <span>What are we missing?</span>
              <a
                href={`https://bugherd.typeform.com/to/e9bhE6vO#ref=${project.organizationId}:${currentUser.id}`}
                target="_blank"
              >
                Suggest an integration
              </a>
            </div>
          }
        />
      )}
    </div>
  );
};

export default (props: Props) => <ParagonSettings {...props} />;
