import React, { FC, RefObject } from 'react';

import UploadList from '../../../../../../javascript/components/uploadList';
import Assignees from './assignees';
import Severity from './severity';
import Tags from './tags';
import Status from './status';
import * as translations from '../strings';
import { getLangKey } from '../../../../../../javascript/models/Language';
import { Button, Checkbox } from 'antd';
import cx from 'classnames';
import { CheckCircle } from 'lucide-react';

import * as Types from '../types';
import { UploadFile } from 'antd/lib/upload/interface';

import styles from './index.module.css';
import { LoginlessTaskInfo } from './loginlessTaskInfo';

type Props = {
  accessTo: Types.AccessTo;
  container: Element;
  fileList: any[];
  removeFile: (file: UploadFile<any>) => void;
  assignees: number[];
  onAssigneesChange: (assigneeIds: number[]) => void;
  severity: Types.Severity;
  onSeverityChange: (priorityId: Types.Severity) => void;
  status?: number;
  columns: Types.Column[];
  onStatusChange: (columnId: number) => void;
  tags: Types.Tag[];
  onTagsChange: (tags: Types.Tag[]) => void;
  availableTags: Types.Tag[];
  submitTask: () => void;
  overlay: Types.Overlay;
  submitDisabled: boolean;
  isSmallView: boolean;
  assignableUsers?: Types.User[];
  rememberTaskSettings: boolean;
  handleCheckboxChange: (checked: boolean) => void;
  createTagsViaNewTask: boolean;
  createTaskButtonRef: RefObject<HTMLElement>;
  nameStatus: 'warning' | 'error' | undefined;
  onNameChange: (name: string) => void;
  emailStatus?: 'warning' | 'error' | undefined;
  onEmailChange: (email: string) => void;
  isLoginlessFeedback: boolean;
  userHasNoFirstName: boolean;
  user: Types.User;
  handleUpdateUserName: () => void;
  setTourStep: (step: number) => void;
  apiDomain: string;
  isAdminView: boolean;
  projectId: number;
  onReturningUserChange: () => void;
};

const strings = translations[getLangKey()];

const RightPanel: FC<Props> = props => {
  const {
    accessTo,
    container,
    fileList,
    removeFile,
    assignees,
    onAssigneesChange,
    severity,
    onSeverityChange,
    status,
    columns,
    onStatusChange,
    tags,
    onTagsChange,
    availableTags,
    submitTask,
    overlay,
    submitDisabled,
    isSmallView,
    assignableUsers,
    rememberTaskSettings,
    handleCheckboxChange,
    createTagsViaNewTask,
    createTaskButtonRef,
    nameStatus,
    onNameChange,
    emailStatus,
    onEmailChange,
    isLoginlessFeedback,
    userHasNoFirstName,
    handleUpdateUserName,
    user,
    apiDomain,
    isAdminView,
    projectId,
    onReturningUserChange
  } = props;

  const {
    canEditAssignees,
    canEditSeverity,
    canEditStatus,
    canEditTags,
    billing,
    assignGuestsAllowed
  } = accessTo;

  const success = overlay === 'success';
  const isGuest = user?.role === 'guest';

  const buttonContent = () => {
    if (success) {
      return {
        icon: <CheckCircle className={styles.checkCircleIcon} />,
        text: strings.taskCreated
      };
    }
    return { text: strings.createButton };
  };

  const { icon, text } = buttonContent();
  const inputSize = isSmallView ? 'large' : 'middle';
  const showLoginlessTaskInfo =
    isGuest && (isLoginlessFeedback || userHasNoFirstName);

  const onClick = () => {
    handleUpdateUserName();
    submitTask();
  };

  return (
    <div className={styles.rightPanel}>
      <div className={styles.innerTop}>
        <div className={styles.inputsContainer}>
          <LoginlessTaskInfo
            show={showLoginlessTaskInfo}
            onNameChange={onNameChange}
            onEmailChange={onEmailChange}
            nameStatus={nameStatus}
            emailStatus={emailStatus}
            user={user}
            projectId={projectId}
            onReturningUserChange={onReturningUserChange}
          />
          {canEditAssignees && (
            <Assignees
              {...{
                onAssigneesChange,
                assignees,
                container,
                inputSize,
                assignableUsers,
                assignGuestsAllowed,
                billing,
                apiDomain,
                isAdminView
              }}
            />
          )}
          {canEditSeverity && (
            <Severity
              {...{ container, severity, onSeverityChange, inputSize }}
            />
          )}
          {canEditStatus && !isGuest && (
            <Status
              {...{
                container,
                status,
                onStatusChange,
                columns,
                inputSize,
                isGuest
              }}
            />
          )}
          {canEditTags && (
            <Tags
              {...{
                container,
                onTagsChange,
                tags,
                availableTags,
                inputSize,
                createTagsViaNewTask
              }}
            />
          )}
        </div>
        {canEditTags && (
          <div className={styles.checkboxContainer}>
            <Checkbox
              className={cx(styles.rememberCheckbox, {
                [styles.checked]: rememberTaskSettings
              })}
              checked={rememberTaskSettings}
              onChange={({ target: { checked } }) =>
                handleCheckboxChange(checked)
              }
            />
            <span className={styles.checkboxLabel}>
              {strings.keepTheseSettings}
            </span>
          </div>
        )}
        <UploadList onRemoveFile={removeFile} fileList={fileList} />
      </div>

      <Button
        id="createTaskButton"
        className={cx(styles.createButton, {
          [styles.submitted]: success
        })}
        type="primary"
        disabled={submitDisabled}
        title={submitDisabled ? strings.disabledButtonTitle : undefined}
        onClick={success ? () => {} : onClick}
        icon={icon}
        ref={createTaskButtonRef}
      >
        {text}
      </Button>
    </div>
  );
};

export default RightPanel;
