export const handleSharingClick = ({
  assetId,
  siteId,
  assetGroupName,
  callback
}: {
  assetId?: number | string;
  siteId?: number | string;
  assetGroupName?: string;
  callback: (assetOrSiteId) => void;
}) => {
  if (!assetGroupName) {
    if (assetId) {
      window.share_project_props.setAssetId(Number(assetId));
    } else if (assetGroupName) {
      window.share_project_props.setAssetGroupName(assetGroupName);
    } else if (siteId) {
      window.share_project_props.setSiteId(Number(siteId));
    }
    window.share_project_props.setShow(true);
  } else {
    callback(assetId || siteId || assetGroupName);
  }
};
